const UNUSUAL_KEY = 'cd1394e6-3fd1-4a2d-ae60-c9ae01f7ee89';
const DEVICE_MEMORY_LIMIT_BYTES = 120000000;

export const NORMAL_MODE = 'normal';
const PRIVATE_MODE = 'pm';
const UNKNOWN_MODE = 'unknown';
const SAFARI_VERSION_WITH_NEW_CHECK = 13;

const isChromiumInPrivateMode = async () => {
    if (navigator?.storage?.estimate) {
        const { quota } = await navigator.storage.estimate();

        return quota < DEVICE_MEMORY_LIMIT_BYTES;
    }

    return undefined;
};

const isFirefoxInPrivateMode = async () => {
    if (!('indexedDB' in window)) {
        return true;
    }

    return new Promise((resolve) => {
        let db;
        try {
            db = indexedDB.open(UNUSUAL_KEY);
        } catch (e) {
            resolve(undefined);
        }

        const timeOutId = setTimeout(() => resolve(undefined), 500);

        const openDbCallback = (event) => {
            clearTimeout(timeOutId);
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1331103#c3
            const errorName = db?.error?.name;
            if (errorName) {
                event.preventDefault();
            }
            resolve(!db.result);
        };

        db.onsuccess = openDbCallback;
        db.onerror = openDbCallback;
    });
};

const isOldSafariInPrivateMode = () => {
    try {
        localStorage[UNUSUAL_KEY] = UNUSUAL_KEY;
        localStorage.removeItem(UNUSUAL_KEY);
    } catch (e) {
        return true;
    }

    try {
        window.openDatabase(null, null, null, null);
    } catch (e) {
        return true;
    }

    return false;
};

const isSafariWithNewCheckInPrivateMode = () => {
    const generateRandomString = function (stringSize) {
        const POSSIBLE_SYMBOLS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const symbolsLength = POSSIBLE_SYMBOLS.length;
        let result = '';

        for (let i = 0; i < stringSize; i++) {
            result += POSSIBLE_SYMBOLS.charAt(Math.floor(Math.random() * symbolsLength));
        }

        return result;
    };

    const randomString = generateRandomString(100);
    const randomStrings = [];

    for (let i = 0; i < 1000; i++) {
        randomStrings.push(randomString);
    }

    const hugeString = randomStrings.join();
    const storageKeys = [];
    try {
        for (let i = 0; i < 30; i++) {
            const key = generateRandomString(10);
            localStorage.setItem(key, hugeString);
            storageKeys.push(key);
        }
        return true;
    } catch (e) {
        return false;
    } finally {
        storageKeys.forEach((key) => localStorage.removeItem(key));
    }
};

const isSafariInPrivateMode = (safariVersion) =>
    safariVersion < SAFARI_VERSION_WITH_NEW_CHECK ? isOldSafariInPrivateMode() : isSafariWithNewCheckInPrivateMode();

const isEdgeOrIeInPrivateMode = () => !window.indexedDB && (window.PointerEvent || window.MSPointerEvent);

export function detectBrowserMode() {
    const { browser, version } = window.bloko.getUserAgentDetails(navigator.userAgent);

    return new Promise(async (resolve) => {
        const privateMode = () => resolve({ browser, mode: PRIVATE_MODE });
        const ordinaryBrowserMode = () => resolve({ browser, mode: NORMAL_MODE });
        const unknown = () => resolve({ browser, mode: UNKNOWN_MODE });

        if (browser === 'chrome' || browser === 'opera') {
            const checkResult = await isChromiumInPrivateMode();

            if (checkResult === undefined) {
                return unknown();
            }

            return checkResult ? privateMode() : ordinaryBrowserMode();
        }

        if (browser === 'firefox') {
            const checkResult = await isFirefoxInPrivateMode();

            if (checkResult === undefined) {
                return unknown();
            }

            return checkResult ? privateMode() : ordinaryBrowserMode();
        }

        if (browser === 'ie' || browser === 'edge' || browser === 'trident') {
            return isEdgeOrIeInPrivateMode() ? privateMode() : ordinaryBrowserMode();
        }

        if (browser === 'safari' || browser === 'ios_saf') {
            return isSafariInPrivateMode(version) ? privateMode() : ordinaryBrowserMode();
        }

        return unknown();
    });
}
